<template>
  <b-card no-body>
    <div id="chart">
      <VueApexCharts type="pie" width="380" style="margin-left: 18%" :options="chartOptions" :series="series"></VueApexCharts>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BDropdownItem, BDropdown,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    criteria: {
      type: Object,
      default: () => { },
    },
    totalEvaluated: {
      type: Number,
      default: () => null,
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        colors: ['#3357FF', '#F5C300', '#6A0DAD', '#10b59c', '#b51f74', '#d4242d', '#b88d28', '#7da31d', '#FF5733', '#07b045'], // Các màu tùy chỉnh
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        tooltip: {
          shared: true,
          intersect: false,
          y: [],
        },
      },
    }
  },
  async created() {
    this.criteria.sliders.forEach(item => {
      this.series.push(item.numberSelected)
      this.chartOptions.labels.push(item.point + " điểm")
      this.chartOptions.tooltip.y.push({
        formatter: function (val) {
          if (typeof val !== "undefined") {
            return " Đếm " + val + " lựa chọn";
          }
        }
      });
    })
  },
}
</script>
